import React, { useContext } from "react"
import { NextPageWithLayout } from "./_app"
import { UserContext } from "../contexts/user"
import { useRouter } from "next/router"
import { WafflesContext } from "../contexts/waffles"

const IndexPage: NextPageWithLayout = () => {
  const { context } = useContext(UserContext)
  const { wafflesContext } = useContext(WafflesContext)
  const router = useRouter()

  if (
    wafflesContext.type !== "" &&
    wafflesContext.flags.indexOf("enable_featured_landing_page") > -1 &&
    !context.isAuthenticated
  ) {
    router.replace("/featured", "/")
  } else {
    router.replace("/marketplace", "/")
  }

  return <></>
}

export const getStaticProps = async (): Promise<unknown> => {
  return {
    props: { isPublicRoute: true },
  }
}

export default IndexPage
